<template>
  <v-app style="background-color: #c9f7db;">
    <div class="items-container">
      <v-card>
        <v-row>
          <v-col cols="5" style="background-color: springgreen; margin: 0;">
            <img src="@/assets/icons/3.png" style="width: 100%; height: 100%;">
          </v-col>
          <v-col cols="5" style="margin: 40px 0 0 60px;">
            <center><img src="@/assets/icons/1.jpg" style="width: 150px; height: 150px;"></center>
            <center><span style="font-size: 25px; margin-bottom: 10px;">{{title}} </span></center>
            <div v-if="disappear === true">
            <v-text-field label="Email" dense outlined v-model="username" v-validate="'required'" name="Username" @keyup="appear = false"></v-text-field>
            <span style="color: red;" v-if="valid">{{ errors.first('Username') }}</span>
            <v-text-field type="password" label="Password" dense outlined @keyup="appear = false" v-model="password"  @keyup.enter="login" v-validate="'required'" name="Password"></v-text-field>
            <span style="color: red;" v-if="valid">{{ errors.first('Password') }}</span>
            <center><CircleBars v-if="loading"/></center>
            <v-btn color="primary" block @click="login" v-if="visible"><span>Login</span></v-btn>
            <span class="forgot" @click="resetpswd">Forgot password?</span>
            </div>
            <div v-if="disappear === false">
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <label>Email address</label>
                <v-text-field
                  v-model="email"
                  name="Email address"
                  outlined
                  dense
                  placeholder="type your email address"
                  v-validate="'required|email'"
                ></v-text-field>
                <span style="color: red;">{{ errors.first('Email address') }}</span>
                <v-btn color="blue"  block style="text-transform: none;color: white;font-size: 15px;" @click="resetPassword">Reset password</v-btn>
                <span @click="disappear = true;title='Login to complete your registration'" style="color: #077bc5;cursor: pointer;">Sign In</span> &nbsp; <span>Instead?</span>
              </v-col>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <v-snackbar
      :timeout="2000"
      :value="true"
      absolute
      top
      color="red"
      right
      text
      v-if="appear"
    >
      {{text}}
    </v-snackbar>
    <v-dialog
      v-model="on"
      max-width="400px"
      :persistent="true"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Reset password</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <label>Email address</label>
                <v-text-field
                  v-model="email"
                  name="Email address"
                  outlined
                  dense
                  placeholder="type your email address"
                  v-validate="'required|email'"
                ></v-text-field>
                <span style="color: red;">{{ errors.first('Email address') }}</span>
                <v-btn color="blue"  block style="text-transform: none;color: white;font-size: 15px;" @click="resetPassword">Reset password</v-btn>
                <span>{{text}}</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="on=false"
            outlined
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import CircleBars from '../statistics/progress-bars/Widgets/CircleBars'
import { mapActions } from 'vuex'

export default {
  name: 'Register',
  components: {
    CircleBars,
  },
  data () {
    return {
      username: '',
      password: '',
      loading: false,
      visible: true,
      valid: true,
      appear: false,
      on: false,
      text: 'Your credentials are invalid',
      email: '',
      disappear: true,
      title: 'Login to complete your registration',

    }
  },
  methods: {
    resetpswd () {
      this.disappear = false
      this.title = 'Resetting your password'
    },
    async login () {
      if (this.username === '' || this.password === '') {
        this.save()
      } else {
        this.loading = true
        this.visible = false
        const data = {
          email: this.username,
          password: this.password,
        }
        try {
          const res = await this.axios.post('login', data)
          if (res.status === 200) {
            this.loading = false
            this.$store.dispatch('commitUpdateUserStatus', res.data.user_status)
            if (localStorage.getItem('currentUrl') === '/login') {
              this.$router.push({ name: 'dashboard' })
            } else {
              this.$router.push(localStorage.getItem('currentUrl') || '/admin/dashboard')
            }
            localStorage.setItem('isLoggeIn', 'yes')
            localStorage.setItem('logged_user', JSON.stringify(res.data))
            console.log(this.$store.state.app.user_status)
          } else {
          }
        } catch (e) {
          if (e.response && e.response.status === 403) {
            setTimeout(() => {
              this.email = ''
              this.password = ''
            }, 1000)
            this.appear = true
            this.valid = false
            this.loading = false
            this.visible = true
          } else {
            console.log(e)
            this.appear = false
          }
        }
      }
    },
    async resetPassword () {
      this.appear = false
      if (this.email === '') {
        this.validate()
      } else {
        this.text = 'Resetting password...'
        const data = {
          email: this.email,
        }
        try {
          const res = await this.axios({
            url: 'reset_password',
            method: 'post',
            data: data,
          })
          if (res.status === 200) {
            this.email = ''
            this.text = 'Check your email for new password'
          } else {
            alert('Data not saved')
          }
        } catch (e) {
          if (e.response && e.response.status === 400) {
            alert(e.response.data.messages)
          } else {
            console.log(e)
          }
        }
      }
    },
    async save () {
      this.$validator.validateAll().then(result => {
        if (result) {
        } else {
          // form have errors
        }
      })
    },
    async validate () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.resetPassword()
        } else {
          // form have errors
        }
      })
    },
  },
}
</script>

<style scoped>
  .items-container {
    width: 1200px;
    margin: 100px 0 0 250px;
  }

  body {
    background-color: #c9f7db;
  }
  .forgot {
    float: right;
    color: #ea4c5a;
    cursor: pointer;
  }
  .forgot >span :hover {
    cursor: pointer;
    color: #0e4ac4;
  }
</style>
